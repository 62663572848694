import { generateChartArraysWith } from "./playlist-data-utils";
import playlistService from "../services/playlists";

export const sortBy = (feature, unsortedPlaylist, sortDesc = false) => {
  // This keeps the order of the tracks that have the same value given a feature
  const sortedPlaylist = [...unsortedPlaylist].sort((a, b) => {
    if (a[feature] < b[feature]) return sortDesc ? 1 : -1;
    if (a[feature] > b[feature]) return sortDesc ? -1 : 1;
    
    return 0;
  });

  return {
    sortedPlaylist,
    ...generateChartArraysWith(sortedPlaylist)
  }
}

export const applyChanges = async (params) => {
  const { sortedPlaylist, currentPlaylist } = params;
  const newOrderUris = sortedPlaylist.map(({ trackUris }) => trackUris);
  const urisChunks = [];
  while (newOrderUris.length) {
    urisChunks.push(newOrderUris.splice(0, 100));
  }
  if (urisChunks.length === 1) {
    await playlistService.updatePlaylist(currentPlaylist.id, urisChunks[0]);
  } else {
    // This will destroy the data!
    await playlistService.updatePlaylist(currentPlaylist.id, []);
    // This will rebuild the playlist in chunks
    for (const urisChunk of urisChunks) {
      await playlistService.rebuildPlaylist(currentPlaylist.id, urisChunk);
    }
  }
}

export const resetSort = (params) => {
  const { chartData, setChartData } = params;
  setChartData(prevChartData => ({
    ...prevChartData,
    sortedPlaylist: undefined,
    ...generateChartArraysWith(chartData.unsortedPlaylist)
  }))
}
